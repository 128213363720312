import { Link } from 'react-router-dom';

export default function Footer(props) {
    const aaStyle = {fontSize: 'calc(16px + 0.5vw)'}
    const smStyle = {fontSize: 'calc(16px + 0.5vw)', marginRight: '1rem', marginLeft: '1rem', color: '#73767c'}
    return (
        <footer>
            <div className='container'>
                <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-3'>
                        <div>
                            <span className='navbar-brand' style={aaStyle}>Attitude Academy</span>
                        </div>
                        <p>Copyright Attitude Academy Ltd.<br />All rights reserved</p>
                    </div>
                    <div className='col-lg-5 text-center'>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/blog'>Blog</Link></li>
                            <li><Link to='/book'>Book</Link></li>
                            <li><a href='mailto:sj@attitudeacademy.co.uk'>Contact</a></li>
                        </ul>
                        <div>
                            <a style={smStyle} href='https://www.facebook.com/groups/369466543458632'><i className="fab fa-facebook-square"></i></a>
                            <a style={smStyle} href='https://twitter.com/yabadabadoit'><i className="fab fa-twitter-square"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}