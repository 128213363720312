const data = [
{
    'id': 1,
    'url': 'https://vimeo.com/680463804?fbclid=IwAR0I0G54WE_QHY3DU9ef70rXOvpbsunbgRfILu5IuDH48RXcVN6w6R-di58',
    'date': '22nd February 2022',
    'host': 'Mary McGuire',
    'title': 'Three steps to unstoppable confidence'
},
{
    'id': 2,
    'url': 'https://podcasts.apple.com/gb/podcast/the-journey-to-the-top-and-why-your-environment/id1606427273?i=1000551962909&fbclid=IwAR0-OaTWSH-034kl_6nkAF0G7VgiwCpxURKoImtHH3C0eQOOCZ3Lx69aG7M',
    'date': '27rd February 2022',
    'host': 'Female CEO Club',
    'title': 'The journey to the top and why your environment has the greatest impact'
},
{
    'id': 3,
    'url': 'https://podcasts.apple.com/gb/podcast/rethinking-leadership/id1525523981?i=1000553288037',
    'date': '8th March 2022',
    'host': 'Rethinking Leadership',
    'title': 'Sarah Foster on confidence and the environment'
},
]

export default data.reverse();