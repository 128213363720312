export default function FourZeroFour(props) {
    return (
            <>
            <div className="container py-5">
                <div className='row justify-content-center'>
                    <div className='col-lg-9'>
                        <p style={{fontSize: 'calc(24px + 0.1vw)'}}>Oops, nothing could be found.</p>
                    </div>
                </div>
            </div>
            </>
        )
}