import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navigation from './layout/Navigation.js';
import Footer from './layout/Footer.js';
import Home from './pages/Home.js';
import Book from './pages/Book.js';
import Podcasts from './pages/Podcasts.js';
import Blog from './pages/Blog.js';
import Blogpost from './pages/Blogpost.js';
import FourZeroFour from './pages/FourZeroFour.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/book" component={Book} />
          <Route exact path="/podcasts" component={Podcasts} />
          <Route exact path="/blog" component={Blog} />
          <Route path="/blog/:postId" component={Blogpost} />
          <Route component={FourZeroFour} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;