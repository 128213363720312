import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import data from '../data.js';

export default function Blogpost(props) {
    const [post, setPost] = useState({});
    useEffect(() => {
        window.scrollTo(0, 0);
        for(let p of data) {
            if(p.id===props.match.params.postId){
                setPost(p);
                if(post.Text) {
                    document.getElementById('postText').innerHTML = post.Text;
                }
            }
        }
    }, [props.match.params.postId, post.Text]);
    if(post&&post.Title&&post.AuthorFirstName&&post.AuthorLastName&&post.Date&&post.Text){
        return (
            <>
            <div className="container py-5">
                <div className='row justify-content-center'>
                    <div className='col-lg-9'>
                        <h2>{post.Title}</h2>
                        <p><span className='pr-3'>{post.AuthorFirstName} {post.AuthorLastName}</span> | <span className='pl-3'>{post.Date}</span></p>
                        <div id='postText'></div>
                    </div>
                </div>
                <div className='text-center pt-5'>
                    <Link to='/blog' className='button-blue-white'>Back to Blog</Link>
                </div>
            </div>
            </>
        )
    }
    else {
        return (
            <>
            <div className="container py-5">
                <div className='row justify-content-center'>
                    <div className='col-lg-9'>
                        <p style={{fontSize: 'calc(24px + 0.1vw)'}}>Oops, nothing could be found.</p>
                    </div>
                </div>
            </div>
            </>
        )
    }
}