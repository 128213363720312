import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function Navigation(props) {
    const [navStyle, setNavStyle] = useState({});
    const [navBrandStyle, setNavBrandStyle] = useState({});
    const [navLinkStyle, setNavLinkStyle] = useState('lightstroke');
    const [navHeight, setNavHeight] = useState(0);
    useEffect(() => {
        setNavHeight(document.getElementsByTagName('nav')[0].getBoundingClientRect().height);
        const listener = () => {
            const nav = document.getElementsByTagName('nav')[0];
            if(window.scrollY < navHeight) {
                if(nav.classList.contains('fixed-top')) {
                    document.getElementsByTagName('nav')[0].classList.remove('fixed-top');
                    setNavStyle({});
                    setNavBrandStyle({});
                    setNavLinkStyle('lightstroke');
                }
            } else {
                if(!nav.classList.contains('fixed-top')) {
                    document.getElementsByTagName('nav')[0].classList.add('fixed-top');
                    setNavStyle({backgroundColor: '#e5ebf0', color: '#333333', boxShadow: '0px 1px 10px grey'})
                    setNavBrandStyle({color: '#333333', fontSize: 'calc(16px + 2vw)'})
                    setNavLinkStyle('darkstroke');
                }
            }
        };
        window.addEventListener('resize', listener);
        window.addEventListener('scroll', listener);
        window.addEventListener('DOMContentLoaded', listener);
        window.addEventListener('load', listener);
        window.addEventListener('scroll', listener);
        return () => {
            window.removeEventListener('resize', listener);
            window.removeEventListener('scroll', listener);
            window.removeEventListener('DOMContentLoaded', listener);
            window.removeEventListener('load', listener);
            window.removeEventListener('scroll', listener);
        }
    }, [navHeight]);
    return (
        <div className='header'>
            <nav className='navbar navbar-expand-lg' style={navStyle}>
                <Link className="navbar-brand" to="/" style={navBrandStyle}>Attitude Academy</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className={"fas fa-ellipsis-v " + navLinkStyle}></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto mr-lg-5">
                        <li className='nav-item'>
                            <Link className={"nav-link " + navLinkStyle} to="/">HOME</Link>
                        </li>
                        <li className="nav-item ml-lg-5">
                            <Link className={"nav-link " + navLinkStyle} to="/blog">BLOG</Link>
                        </li>
                        <li className="nav-item ml-lg-5">
                            <Link className={"nav-link " + navLinkStyle} to="/book">BOOK</Link>
                        </li>
                        <li className="nav-item ml-lg-5">
                            <Link className={"nav-link " + navLinkStyle} to="/podcasts">PODCASTS</Link>
                        </li>
                        <li className="nav-item ml-lg-5">
                            <a className={"nav-link " + navLinkStyle} href="mailto:sj@attitudeacademy.co.uk">CONTACT</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}