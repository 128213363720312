import { useEffect, useState } from 'react';
import YDDI from '../YabadabadoitCover.png';

export default function Book(props) {
    const [shift, setShift] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        const getHeight = document.getElementById('divShift').offsetHeight;
        const newShift = '-' + getHeight + 'px';
        setShift(newShift);
        const resizeListener = () => {
            const getHeight = document.getElementById('divShift').offsetHeight;
            const newShift = '-' + getHeight + 'px';
            setShift(newShift);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);
    let divShift = {
        marginTop: shift
    };
    return (
        <>
        <div id='divShift' style={divShift}>
            <h1>Book</h1>
        </div>
        <div style={{fontSize: 'calc(26px + 0.1vw)'}}>
            <div className="container py-5">
                <div className="row align-items-center">
                    <div className="col-lg-6 text-center">
                        <img src={YDDI} style={{width: '75%', boxShadow: '5px 5px 10px #00000050'}} alt="Book Cover" />
                    </div>
                    <div className="col-lg-6">
                        <div className="container text-center py-5">
                            <h2>YABADABADOIT</h2>
                            <p>by Sarah Foster</p>
                            <p style={{fontSize: 'calc(20px + 0.1vw)', marginBottom: '2rem'}}>"If you have ever been disadvantaged by a lack of confidence or self-doubt then Yabadabadoit is for you, it offers a 3-step guide to mastering the art of staying in control of your own confidence, allowing you to pursue all of your dreams with your own authentic style of self-belief" - <b>Hal Elrod, international keynote speaker and best-selling author of The Miracle Morning and The Miracle Equation</b></p>
                            <a className='button-blue-white' href='https://www.amazon.co.uk/dp/B08SHZ845C/ref=cm_sw_em_r_mt_dp_Esz-Fb3S5B8KT'>Get your copy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}