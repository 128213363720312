import { useState, useEffect } from 'react';
import data from '../podcast_data.js';

export default function Podcasts(props) {
    const [shift, setShift] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        const getHeight = document.getElementById('divShift').offsetHeight;
        const newShift = '-' + getHeight + 'px';
        setShift(newShift);
        const resizeListener = () => {
            const getHeight = document.getElementById('divShift').offsetHeight;
            const newShift = '-' + getHeight + 'px';
            setShift(newShift);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);
    let divShift = {
        marginTop: shift
    };
    return (
        <>
        <h1 id='divShift' style={divShift}>Podcasts</h1>
        <div className="container blog-snippet">
            {data.map((post) => {
                const to = post.url;
                return (
                <div className='row justify-content-center' style={{marginBottom: '3rem'}} key={post.id}>
                    <div className='col-lg-8 col-md-10'>
                        <h3>{post.title}</h3>
                        <p><span className='pr-3'>{post.host}</span> | <span className='pl-3'>{post.date}</span></p>
                        <a className='button-blue-white' href={to}>Watch/Listen</a>
                    </div>
                </div>
                )
            })}
        </div>
        </>
    )
}