import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../layout/Banner.js';
import YDDI from '../YabadabadoitCover.png';
import data from '../data.js';

export default function Home(props) {
    const white = {
        backgroundColor: '#e5ebf0',
        padding: '5%',
        textAlign: 'center',
        borderRadius: '4px 4px 0px 0px',
    }
    const [shift, setShift] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        const getHeight = document.getElementById('divShift').offsetHeight;
        const newShift = '-' + (getHeight / 2.0) + 'px';
        setShift(newShift);
        const resizeListener = () => {
            const getHeight = document.getElementById('divShift').offsetHeight;
            const newShift = '-' + (getHeight / 2.0) + 'px';
            setShift(newShift);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);
    let divShift = {
        marginTop: shift
    };
    return (
        <>
        <div className="container" id="divShift" style={divShift}>
            <div className="row justify-content-left">
                <div className="col-lg-6">
                    <div style={white}>
                        <h2 style={{fontWeight: '300'}}>Impossible itself says <em style={{fontWeight: '600'}}>I'm possible</em></h2>
                    </div>
                </div>
            </div>
        </div>
        <div className="container col-text">
            <p>At Attitude Academy we believe anything is possible, the word impossible itself says ‘I’m possible’. We are on a mission to help as many people as we can, realise the confidence they hold within.</p>
            <p>In our debut book ‘Yabadabadoit’ we share the 3 simple steps to harnessing your authentic confidence so that you can create the life you want.</p>
            <p>Yabadabadoit is aimed at anyone who has lacked confidence and would like to learn how to become more confident in order to achieve their goals.</p>
            <p>It’s is widely accepted that the human brain is wired to keep us safe. This is inherent from pre-historic times when there was real and present danger every time you stepped outside of your comfort zone (or your cave, as the case may have been all those years ago). As humanity has evolved, such dangers have too and whilst new dangers have surfaced, others are no longer present, but do we still act as though they are?</p>
            <p>For many of us, our brains, thoughts, feelings and actions, particularly reactions, are still in protective mode, still working hard to keep us safe and warning us at every juncture to get back in our comfort zone quick!</p>
            <p>But is your comfort zone really the best place to stay? If you have ever regretted not speaking up, not going for what you wanted or not putting yourself forward then Yabadabadoit could be the answer.</p>
            <p>It offers a simple strategy to overcome doubt and strut out of your cave.</p>
        </div>
        <Banner bg='var(--blue-bg)' text='Are you ready to learn how to harness your inner confidence?' linkUrl='/book' linkText='Yabadabadoit'/>
        <div style={{backgroundColor: 'var(--light-blue-bg)', fontSize: 'calc(26px + 0.1vw)'}}>
            <div className="container py-5">
                <div className="row align-items-center">
                    <div className="col-lg-6 text-center">
                        <img src={YDDI} style={{width: '75%', boxShadow: '5px 5px 10px #00000050'}} alt="Book Cover" />
                    </div>
                    <div className="col-lg-6">
                        <div className="container text-center py-5">
                            <h2>YABADABADOIT</h2>
                            <p>by Sarah Foster</p>
                            <p style={{fontSize: 'calc(20px + 0.1vw)', marginBottom: '2rem'}}>"If you have ever been disadvantaged by a lack of confidence or self-doubt then Yabadabadoit is for you, it offers a 3-step guide to mastering the art of staying in control of your own confidence, allowing you to pursue all of your dreams with your own authentic style of self-belief" - <b>Hal Elrod, international keynote speaker and best-selling author of The Miracle Morning and The Miracle Equation</b></p>
                            <a className='button-blue-white' href='https://www.amazon.co.uk/dp/B08SHZ845C/ref=cm_sw_em_r_mt_dp_Esz-Fb3S5B8KT'>Get your copy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
        <div className='container py-5 text-center'>
            <div className='row justify-content-center'>
                {data.slice(0,3).map((post) => {
                    const to = '/blog/' + post.id;
                    return (
                    <div key={post.id} className={'col-lg-'+(12/data.slice(0,3).length)+' pb-2'} style={{textAlign: 'center'}}>
                        <h3>{post.ShortTitle}</h3>
                        <p>{post.Date} by {post.AuthorFirstName} {post.AuthorLastName}</p>
                        <p>{post.Snippet}</p>
                        <Link className='orange' to={to}>Read more</Link>
                    </div>
                    )
                })}
            </div>
        </div>
        </div>
        <Banner bg='var(--orange-bg)' buttonColor='white-orange' text='Go to the blog to see more posts' linkUrl='/blog' linkText='Go to the blog'/>
       
        </>
    )
}