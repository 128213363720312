export default function Banner(props) {
    const background = {
        backgroundColor: props.bg || 'grey',
        color: props.fg || '#f0f0f0',
        fontSize: 'calc(24px + 0.5vw)',
        fontWeight: '300'
    }
    const buttonClass = 'button-'+(props.buttonColor || 'white-blue');
    
    const MyRender = (props) => {
        if(props.linkUrl && props.linkText){
            return (
                <div style={background}>
                    <div className='container py-4'>
                        <div className="row align-items-center">
                            <div className="col-lg-9 text-center">
                                <p style={{marginBottom: '0px'}}>{props.text || 'Please use the props.text attribute'}</p>
                            </div>
                            <div className="col-lg-3 text-center py-4">
                                <a className={buttonClass} href={props.linkUrl}>{props.linkText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={background}>
                    <div className='container py-4'>
                        <p style={{marginBottom: '0px'}}>{props.text || 'Please use the props.text attribute'}</p>
                    </div>
                </div>
            )
        }
    };

    return (
        <MyRender text={props.text} linkUrl={props.linkUrl} linkText={props.linkText}/>
    )
}